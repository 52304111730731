import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import EmployeesSelect from '../../project/EmployeesSelect';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import CustomersSelect from '../../project/CustomersSelect';
import RequestStatesSelect from '../../project/RequestStatesSelect';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import DateRangePicker from '../../general/DateRangePicker';
import InputText from '../../general/InputText';
import {getQueryParam, createSetQueryParamValue, navigateToParametrized} from '../../../lib/url';
import {getInputOnChangeEventValue} from '../../../lib/project';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {TRADE} from '../../../constants/trade';
import {RIGHTS} from '../../../constants/Rights';
import {Button, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

export const REQUEST_CLEAR_PARAMS = {
    [QUERY_PARAMS.REQUEST_TRADE]: undefined,
    [QUERY_PARAMS.REQUEST_REF]: undefined,
    [QUERY_PARAMS.REQUEST_CUSTOMER_REF]: undefined,
    [QUERY_PARAMS.REQUEST_CREATED_AT]: undefined,
    [QUERY_PARAMS.REQUEST_ID_DEALER]: undefined,
    [QUERY_PARAMS.REQUEST_ID_SALESMAN]: undefined,
    [QUERY_PARAMS.REQUEST_ID_SALESMAN2]: undefined,
    [QUERY_PARAMS.REQUEST_ID_USER]: undefined,
    [QUERY_PARAMS.REQUEST_ID_STATUS]: undefined,
    [QUERY_PARAMS.REQUEST_NOTICES]: undefined,
    [QUERY_PARAMS.REQUEST_ID_CUSTOMER]: undefined,
    [QUERY_PARAMS.REQUEST_ORDER_BY]: undefined,
    [QUERY_PARAMS.REQUEST_SCROLL]: undefined,
    [QUERY_PARAMS.REQUEST_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
};

class RequestParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, REQUEST_CLEAR_PARAMS);
    };

    render() {
        const {location} = this.props;
        const trade = getQueryParam(location, QUERY_PARAMS.REQUEST_TRADE);
        const dealerRef = getQueryParam(location, QUERY_PARAMS.REQUEST_REF);
        const customerRef = getQueryParam(location, QUERY_PARAMS.REQUEST_CUSTOMER_REF);
        const createAtDateString = getQueryParam(location, QUERY_PARAMS.REQUEST_CREATED_AT);
        const dealerId = getQueryParam(location, QUERY_PARAMS.REQUEST_ID_DEALER);
        const salesmanId = getQueryParam(location, QUERY_PARAMS.REQUEST_ID_SALESMAN);
        const salesman2Id = getQueryParam(location, QUERY_PARAMS.REQUEST_ID_SALESMAN2);
        const userId = getQueryParam(location, QUERY_PARAMS.REQUEST_ID_USER);
        const statusId = getQueryParam(location, QUERY_PARAMS.REQUEST_ID_STATUS);
        const notices = getQueryParam(location, QUERY_PARAMS.REQUEST_NOTICES);
        const customerId = getQueryParam(location, QUERY_PARAMS.REQUEST_ID_CUSTOMER);
        const hasFilter = trade != null || dealerRef != null || customerRef != null || dealerId != null || userId != null || 
            customerId != null || statusId != null || notices != null || createAtDateString != null || salesmanId != null || salesman2Id != null;
        return <ColumnFilterLayout
            className="p-1"
            filters={[
                [
                    {
                        label: <Trans>Typ: </Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_TRADE)(value);
                            }}
                            value={trade}
                        >
                            <Radio.Button value={TRADE.SELL}><Trans>Prijaté</Trans></Radio.Button>
                            <Radio.Button value={TRADE.BUY}><Trans>Odoslané</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Číslo dopytu: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_REF)}
                            value={dealerRef}
                        />,
                    },
                    {
                        label: <Trans>Č. dopytu u zákazníka: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_CUSTOMER_REF)}
                            value={customerRef}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Stav: </Trans>,
                        selector: <RequestStatesSelect
                            value={statusId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_STATUS)}
                            allowClear={true}
                        />,
                        rightsTo: RIGHTS.DISTRIBUTOR
                    },
                    {
                        label: <Trans>Poznámka: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_NOTICES)}
                            value={notices}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Stav: </Trans>,
                        selector: <RequestStatesSelect
                            className="full-size-width"
                            value={statusId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_STATUS)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING
                    },
                ],
                [
                    {
                        label: <Trans>Organizácia: </Trans>,
                        selector: <CustomersSelect
                            className="full-size-width"
                            value={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_CUSTOMER)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Kontakt: </Trans>,
                        selector: <UserSelectByCustomer
                            className="full-size-width"
                            value={userId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_USER)}
                            allowClear={true}
                            customerId={customerId}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Vybavuje: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={dealerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_DEALER)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Zodp. obchodník: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesmanId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_SALESMAN)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        label: <Trans>Logista: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesman2Id}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_ID_SALESMAN2)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                ],
                [
                    {
                        label: <Trans>Odoslané: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.REQUEST_CREATED_AT)}
                            value={createAtDateString}
                        />,
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ]
            ]}
        />;
    }

}

export default locationHOC(RequestParams);