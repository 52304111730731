import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import RequestTable from './requests/RequestTable';
import RequestParams from './requests/RequestParams';
import MakeRequestWithItem from './requests/MakeRequestWithItem';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Request} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { QUERY_PARAMS } from '../../constants/navigation';

/**
 * @fero
 */

class RequestsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.REQUESTS]: PropTypes.arrayOf(Request.isRequired).isRequired,
    };

    render() {
        const {[GLOBAL_DATA.REQUESTS]: requests, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Dopyty`}
            />
            <TableWithFiltersLayout
                title={<Trans>Dopyty</Trans>}
                params={
                    <div>
                        <RequestParams/>
                        <MakeRequestWithItem wrapperClassName="px-3 pt-2"/>
                    </div>
                }
                table={
                    <RequestTable
                        reload={() => reload([GLOBAL_DATA.REQUESTS])}
                        requests={requests}
                    />
                }
                scrollParam={QUERY_PARAMS.REQUEST_SCROLL}
            />
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.REQUESTS, GLOBAL_DATA.RELOAD_DATA])(RequestsPage);